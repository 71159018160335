/* components/ReviewPanel.css */
.review-panel-container {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.review-panel-header {
    margin-bottom: 20px;
}

.review-panel-header h2 {
    color: var(--text-color);
    margin: 0 0 8px 0;
    font-size: 20px;
}

.apartment-name {
    color: var(--main-color);
    font-size: 14px;
}

.reviews-list {
    flex: 1;
    overflow-y: auto;
}

.no-reviews {
    color: var(--text-color);
    opacity: 0.7;
    text-align: center;
    padding: 20px;
}