.topbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: var(--card-background);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.topbar-logo {
  flex: 1;
}

.logo {
  height: 40px;
  width: auto;
}

.view-toggle {
  display: flex;
  gap: 1rem;
  background-color: var(--background-color);
  padding: 0.5rem;
  border-radius: 8px;
}

.toggle-btn {
  padding: 0.5rem 1.5rem;
  border: none;
  border-radius: 6px;
  background: none;
  color: var(--text-color);
  cursor: pointer;
  transition: all 0.3s ease;
}

.toggle-btn.active {
  background-color: var(--main-color);
  color: var(--background-color);
}

.language-selector {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  position: relative;
}

.language-btn {
  padding: 0.5rem 1rem;
  background-color: var(--background-color);
  color: var(--text-color);
  border: 1px solid var(--main-color);
  border-radius: 6px;
  cursor: pointer;
}

.language-dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 0.5rem;
  background-color: var(--card-background);
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.language-option {
  display: block;
  width: 100%;
  padding: 0.75rem 1.5rem;
  text-align: left;
  background: none;
  border: none;
  color: var(--text-color);
  cursor: pointer;
}

.language-option:hover {
  background-color: var(--main-color);
  color: var(--background-color);
}

.logo-text {
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 1px;
  margin: 0;
}

.logo-review {
  color: var(--text-color);
}

.logo-dot {
  color: var(--main-color);
}

.logo-ae {
  color: var(--text-color);
}

.logo-text:hover .logo-review,
.logo-text:hover .logo-ae {
  color: var(--main-color);
  transition: color 0.3s ease;
}