/* components/ReviewCard.css */
.review-card {
    background: var(--card-bg-color, #0a37032d);
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.review-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
}

.user-info {
    display: flex;
    flex-direction: column;
}

.user-name {
    font-weight: 600;
    color: var(--text-color);
    margin-bottom: 4px;
}

.review-date {
    font-size: 12px;
    color: var(--text-secondary);
}

.rating {
    color: #ffd700;  /* 별점 색상 */
    font-size: 16px;
}

.review-content {
    color: var(--text-color);
    line-height: 1.5;
    margin-bottom: 12px;
}

.review-source {
    font-size: 12px;
    color: var(--text-secondary);
}

/* Reddit 리뷰 특화 스타일 */
.review-card[data-type="reddit"] .review-source {
    display: flex;
    align-items: center;
    gap: 8px;
}

.reddit-link {
    color: var(--main-color);
    text-decoration: none;
    font-size: 12px;
}

.reddit-link:hover {
    text-decoration: underline;
}

/* 다크 모드 대응 */
@media (prefers-color-scheme: dark) {
    .review-card {
        background: var(--card-bg-color-dark, #2a2a2a);
    }
}

/* Reddit 리뷰 스타일 */
.review-card.reddit-review {
    cursor: pointer;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    position: relative;
}

.review-card.reddit-review:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.reddit-icon {
    width: 16px;
    height: 16px;
    margin-right: 4px;
    vertical-align: middle;
}

.reddit-link-hint {
    font-size: 12px;
    color: var(--main-color);
    opacity: 0.8;
}

.review-card.reddit-review:hover .reddit-link-hint {
    opacity: 1;
}

/* Reddit 리뷰 제목 스타일 */
.reddit-review .user-name {
    font-weight: bold;
    color: var(--main-color);
}

.reddit-score {
    display: flex;
    align-items: center;
    gap: 4px;
    color: var(--text-secondary);
}