/* App.css */
:root {
  --main-color: #70B892;
  --background-color: #0A140F;
  --text-color: #D7E5DE;
  --card-background: #0A140F;  /* 배경색보다 약간 밝은 색상 */
  --hover-color: #8BCAA6;      /* 메인 컬러보다 약간 밝은 색상 */
}

body {
  background-color: var(--background-color);
  color: var(--text-color);
}

.app-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.divider-line {
  height: 1px;
  background-color: rgba(112, 184, 146, 0.2); /* 메인 컬러 #70B892의 20% 투명도 */
  width: 100%;
}

.main-content {
  display: flex;
  flex: 1;
  gap: 42px;
  padding: 16px;
  min-height: 0; /* 스크롤 관련 버그 방지 */
}

.map-container {
  flex: 2;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  border: 1px solid var(--main-color);
}

/* SearchBar 스타일링 */
.search-container {
  padding: 20px;
  background-color: var(--card-background);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.search-input {
  width: 100%;
  max-width: 600px;
  padding: 12px 20px;
  font-size: 16px;
  color: var(--text-color);
  background-color: var(--background-color);
  border: 2px solid var(--main-color);
  border-radius: 8px;
  outline: none;
  transition: all 0.3s ease;
}

.search-input:focus {
  border-color: var(--hover-color);
  box-shadow: 0 0 0 3px rgba(112, 184, 146, 0.25);
}

.search-input::placeholder {
  color: var(--text-color);
  opacity: 0.7;
}

/* ReviewPanel 스타일링 */
.review-panel {
  width: 470px;
  background-color: var(--card-background);
  overflow-y: auto;
  overflow-x: hidden;
  padding: 16px;
}

/* ApartmentInfo 스타일링 */
.apartment-info {
  flex: 1;
  background-color: var(--card-background);
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  overflow-y: auto;
}

/* Marker Popup 스타일링 */
.leaflet-popup-content-wrapper {
  background-color: var(--card-background);
  color: var(--text-color);
  border: 1px solid var(--main-color);
}

.leaflet-popup-tip {
  background-color: var(--card-background);
  border: 1px solid var(--main-color);
}

.leaflet-popup-content {
  font-size: 14px;
  font-weight: 500;
}

/* 스크롤바 스타일링 */
::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background: var(--background-color);
}

::-webkit-scrollbar-thumb {
  background: var(--main-color);
  border-radius: 2px;
  opacity: 0.7;
}

::-webkit-scrollbar-thumb:hover {
  background: #91B6A2;
}

/* Firefox를 위한 스크롤바 스타일링 */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--main-color) var(--background-color);
}

/* 선택 텍스트 타일링 */
::selection {
  background-color: var(--main-color);
  color: var(--background-color);
}

/* 반응형 디자인을 위한 미디어 쿼리는 다음 단계에서 추가하겠습니다 */

.leaflet-container a {
  color: var(--main-color);
}

.leaflet-popup-close-button {
  color: var(--text-color) !important;
}

.leaflet-control-zoom a {
  background-color: var(--card-background) !important;
  color: var(--text-color) !important;
  border-color: var(--main-color) !important;
}

.leaflet-control-zoom a:hover {
  background-color: var(--main-color) !important;
  color: var(--background-color) !important;
}

/* App.css에 추가 */
.leaflet-container {
    background-color: #f5f5f5;
    border-radius: 12px;
}

.leaflet-popup-content-wrapper {
    background-color: var(--card-background);
    color: var(--text-color);
}

.leaflet-popup-tip {
    background-color: var(--card-background);
}

.leaflet-popup-close-button {
    color: var(--text-color) !important;
}

.map-dark {
    filter: invert(92%) hue-rotate(180deg) brightness(0.9) contrast(85%) saturate(0.9);
}

.map-dark .leaflet-tile {
    filter: brightness(0.8) contrast(1.1) saturate(0.8);
}

/* 마커와 팝업은 테마 색상과 일치하도록 */
.map-dark .leaflet-marker-icon,
.map-dark .leaflet-popup-content-wrapper,
.map-dark .leaflet-popup-tip {
    filter: invert(1) hue-rotate(180deg);
}

/* 컨트롤 스타일링 */
.leaflet-control-zoom {
    border: none !important;
    box-shadow: none !important;
}

.leaflet-control-zoom a {
    background-color: var(--card-background) !important;
    color: var(--text-color) !important;
    border: 1px solid var(--main-color) !important;
    transition: all 0.3s ease !important;
}

.leaflet-control-zoom a:hover {
    background-color: var(--main-color) !important;
    color: var(--background-color) !important;
}

/* 지도 컨테이너 스타일링 */
.map-container {
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    border: 1px solid var(--main-color);
}

/* 팝업 스타일링 */
.leaflet-popup-content-wrapper {
    background-color: var(--card-background);
    color: var(--text-color);
    border: 1px solid var(--main-color);
}

.leaflet-popup-tip {
    background-color: var(--card-background);
    border: 1px solid var(--main-color);
}

.topbar-line {
  height: 1px;
  background-color: rgba(145, 182, 162, 0.2); /* #91B6A2 with 20% opacity */
  width: 100%;
}

.map-section {
  flex: 1;
  position: relative;
  transition: all 0.3s ease;
}

.map-section.expanded {
  flex: 1;
}

.info-panel {
  width: 400px;
  position: relative;
  background-color: var(--card-background);
  overflow-y: auto;
  overflow-x: hidden;
  min-width: 300px;
  max-width: 600px;
  padding: 16px;
}

.close-btn {
  position: absolute;
  right: 10px;
  top: 10px;
  background: none;
  border: none;
  color: var(--text-color);
  font-size: 24px;
  cursor: pointer;
  padding: 5px;
  line-height: 1;
  z-index: 1000;
}

.close-btn:hover {
  color: var(--main-color);
}

/* 리사이즈 핸들 스타일링 */
.info-panel::-webkit-resizer {
  background-color: var(--main-color);
  border: 2px solid var(--card-background);
}

/* 드래그 가능한 구분선 추가 */
.resizer {
  width: 4px;
  cursor: col-resize;
  background-color: var(--main-color);
  opacity: 0.2;
  transition: opacity 0.3s ease;
}

.resizer:hover {
  opacity: 0.5;
}

/* 리사이즈 가능한 패널들의 공통 스타일 */
.resizable {
  position: relative;
}

.resizable::-webkit-resizer {
  background-color: var(--main-color);
  border: 2px solid var(--card-background);
}

/* 닫기 버튼 스타일 */
.close-btn {
  position: absolute;
  right: 10px;
  top: 10px;
  background: none;
  border: none;
  color: var(--text-color);
  font-size: 24px;
  cursor: pointer;
  padding: 5px;
  line-height: 1;
  z-index: 1000;
}

.close-btn:hover {
  color: var(--main-color);
}

/* 패널 내부 패딩 */
.review-panel, .info-panel {
  padding: 10px;
}

.bottom-bar {
  height: 60px;
  background-color: var(--card-background);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 21px;
}

.bottom-bar-content {
  color: var(--text-color);
  opacity: 0.7;
  font-size: 14px;
}

/* 기존 main-content의 height 계산 수정 */
.main-content {
  height: calc(100vh - 132px); /* 71px(탑바) + 60px(바텀바) + 1px(구분선) */
}

.map-search {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  width: 90%;
  max-width: 400px;
  display: flex;
  align-items: center;
}

.map-search-input {
  width: 100%;
  padding: 12px 20px;
  background-color: rgba(0, 0, 0, 0.303);
  border: 1px solid var(--main-color);
  border-radius: 8px;
  color: var(--text-color);
  font-size: 14px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.map-search-input:focus {
  outline: none; /* 기본 파란색 아 제거 */
  border-color: var(--main-color); /* 메인 컬러로 테두리 색상 변경 */
  box-shadow: 0 0 0 2px rgba(112, 184, 146, 0.2); /* 메인 컬러의 부드러운 그림자 */
}

.map-search-input::placeholder {
  color: var(--text-color);
  opacity: 0.7;
}

.map-search-reset {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: var(--text-color);
  font-size: 18px;
  cursor: pointer;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.7;
  transition: all 0.2s ease;
}

.map-search-reset:hover {
  opacity: 1;
  color: var(--main-color);
}

/* 모바일 스타일 (767px 이하) */
@media screen and (max-width: 767px) {
  .main-content.mobile {
    flex-direction: column;
    gap: 2%;
    padding: 3%;
    height: auto;
    min-height: calc(100vh - 132px);
  }

  .map-section.mobile {
    height: 45vh; /* 화면 높이의 45% */
    width: 100%;
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    z-index: 1;
  }

  .info-panel.mobile {
    width: 100%;
    height: auto;
    min-height: 25vh; /* 화면 높이의 25% */
    max-height: 35vh; /* 화면 높이의 35% */
    resize: none;
    border-radius: 8px;
    margin: 2% 0;
  }

  .review-panel.mobile {
    width: 100%;
    height: auto;
    min-height: 30vh; /* 화면 높이의 30% */
    border-radius: 8px;
  }

  .map-search {
    width: 70%;
    left: 45%;
    transform: translateX(-50%);
  }

  .map-search-input {
    padding: 8px 16px;
    font-size: 13px;
  }

  /* 모바일에서 스크롤 가능하도록 */
  .info-panel.mobile,
  .review-panel.mobile {
    overflow-y: auto;
  }

  /* 모바일에서 닫기 버튼 위치 조정 */
  .info-panel.mobile .close-btn {
    top: 8px;
    right: 8px;
  }

  .bottom-bar {
    height: 40px;
  }

  .bottom-bar-content {
    font-size: 12px;
  }

  /* 맵 컨테이너가 클릭 이벤트를 받을 수 있도록 */
  .leaflet-container {
    z-index: 1;
    touch-action: none; /* 터치 이벤트 개선 */
  }

  /* 마커가 클릭 가능하도록 */
  .leaflet-marker-icon {
    z-index: 2;
  }

  /* 팝업이 다른 요소 위에 표시되도록 */
  .leaflet-popup {
    z-index: 3;
  }
}

/* 태블릿 스타일 (768px ~ 1023px) */
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .main-content {
    flex-direction: column;
    gap: 20px;
    padding: 16px;
  }

  .review-panel {
    width: 100%;
    height: 300px;
  }

  .map-section {
    height: 400px;
  }

  .info-panel {
    width: 100%;
    max-width: none;
    height: 300px;
  }
}